import "jquery";
import { trackEvent } from "modules/analytics";
import { getJSON } from "modules/api";
import * as cms from "modules/cms";
import { hideLogoSplash } from "modules/navigation";
import { toastError } from "modules/toasts";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import DynamicPage from "vue-components/dynamic-page.vue";

$(() => {

    getJSON(cms.buildPageEndpointUrl("/index")).then((homePageJSON) => {
        if (homePageJSON.entity && homePageJSON.entity.containers) {

            const app = createApp(DynamicPage,
                {
                    cmsPageResponse: homePageJSON
                }
            );
            mountApp(app, "page-app");

            hideLogoSplash();

            trackEvent("entry_view", { item_name: "Virtuoso_Home", item_category: "Virtuoso_Homepage" });
        } else {
            console.error("Error retrieving data, no containers found: ", homePageJSON);
        }
    }, (e) => {
        toastError("Error retrieving data");
        console.error("Error retrieving data for /index: ", e);
    });

});
